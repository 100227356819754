
.content {
  display: grid;
  grid-template-columns: auto 200px;
  height: 100%;
  .sidebar {
    border-left: 1px solid black;
    height: 100%;
    margin-left: 1em;
  }
}

.artwork-cols {
  display: grid;
  grid-template-columns: 300px auto;

  .artwork-wrapper {
    aspect-ratio: 1 / 1;
    background-color: lightgrey;
    position: relative;
    
    .artwork-label, .artwork-image {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      aspect-ratio: 1/1;
    }
  }
}

.input-element {
  width: 100%;
  padding: .5em;
  border: none;
  border-bottom: 1px solid var(--text-color);
  &:focus {
    outline: none;
  }
}

.textarea-element {
  min-height: 15em;
}

.general {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1px;

  .cell {
    padding: .5em;
    box-sizing: border-box;
    box-shadow:0 0 0 1px var(--shadow-background-color);


    &.info {
      font-weight: bold;
    }
  }
}

.episode-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  .episode {
    list-style: none;
    padding: 1em;
    border-radius: 0 5px 0 5px;
    box-shadow: 2px 2px 10px -3px var(--text-color);
  }
}

@media (max-width: 800px) {
  .content {
    grid-template-columns: auto;
    .main {
      order: 2;
    }
    .artwork-cols {
      grid-template-columns: auto;
    }
    .general {
      grid-template-columns: 1fr 1fr;
    }
    .sidebar {
      margin-top: 1em;
      order: 1;
      border: none;
    }
  }
}
