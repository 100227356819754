@import '@/main.scss';

.podcast-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  .list-entry  {
    list-style: none;
    padding: 1em;
  }
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.podcast {
  display: flex;
  flex-direction: column;
  gap: 1em;
  .title {
    font-weight: bold;
  }
}