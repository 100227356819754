@import '@/main.scss';

.outer {
  position: absolute;
  top: 0px; bottom: 0px;
  left: 0px; right: 0px;
  background-color: transparent;

  .menu {
    position: absolute;
    top: 80px;
    right: 10px;
    width: 400px;
    border-radius: 5px;
    background-color: #530000;
    padding: 1em;
    box-sizing: border-box;
    color: white;

    @media (max-width: 400px) {
      right: calc(10% / 2);
      width: 90%;
    }

    .current-mail {
      text-align: center;
      color: white;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .logout {
      text-align: center;
      padding: 1em;
    }

    .account-list {
      background-color: #eee;
      border-radius: 5px;
      padding: .5em;
      box-sizing: border-box;
      .add-account {
        margin-top: 1em;
        text-align: center;
        button {
          border: none;
          background-color: #7c5757;
          color: white;
          padding: .5em;
          box-sizing: border-box;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      .account-list-entry {
        padding: 1em 0em;
        box-sizing: border-box;
        display: flex;
        padding: .25em;
        margin: .25em;
        gap: 1em;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: #7c5757;
        }
        .acc {
          
          display: flex;
          flex-direction: column;
          .name {
            font-weight: bold;
          }
          .email {
            font-size: small;
          }
        }
      }
    }
  }
}