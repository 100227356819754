@import '@/main.scss';
.list {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1em;
  margin: 1em;

  .actions {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    gap: 1em;
    justify-content: center;
  }

  @media (max-width: $mobileBreakpoint) {
    grid-template-columns: auto;
    .actions {
      grid-column: auto;
    }
  }
}

.card {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 1em;
  color: $textColor;
  text-decoration: none;
  p {
    margin: 0;
  }

}

.icon {
  aspect-ratio: 1 / 1;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  box-sizing: border-box;
}