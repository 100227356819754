@import '@/main.scss';

.list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .list-entry {
    padding: 0;
    list-style: none;

    .podcast-info {
      display: flex;
      align-items: center;
      gap: 1em;

      .main-title {
        margin: 0;
        font-weight: bold; 
      }
      .sub-title {
        margin: 0;
        font-size: x-small;
      }
    }

    .actions {
      margin: .5em 0;
      display: flex;
      gap: .5em;
    }
  }
}