@import '@/main.scss';

.columns {
  display: flex;
  flex-direction: column;
  gap: 1em;
  @media (min-width: $mobileBreakpoint) {
    display: grid;
  }
}

.column {
  background-color: $lightRed;
  .headline {
    padding: .5em;
    display: flex;
    border-bottom: 1px solid $accentRed;
    .title {
      width: 100%;
      font-weight: bold;
      
    }
  }
  @media (max-width: $mobileBreakpoint) {
    .tickets {
      overflow: hidden;
      transition: max-height .3s ease, overflow-y .3s ease;
      &.open {
        max-height: 50vh;
        overflow-y: auto;
      }
      &.closed {
        max-height: 0;
      }
    }
  }
}

.card {
  padding: .5em;
  margin: .5em;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: space-between;
  &.closed {
    text-decoration: line-through;
    
  }
}

.ticket-modal {
  p {
    margin: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .base {
      .title {
        font-size: larger;
      }
      .author, .created-at {
        font-size: x-small;
      }
    }
    .type {
      text-align: right;
    }
  } 
}

.add-message {
  textarea {
    width: 100%;
    min-height: 3em;
  }
}

.message-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: .5em;
  li {
    list-style: none;
  }
}

.message {
  list-style: none;
  padding: 1em;
  display: flex;
  gap: 1em;
  border-bottom: .5px solid #eee;
  .creator {
    width: 3em;
  }
  .content {
    width: 100%;
  }
  .header {
    display: flex;
    .author {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1em;
      .name {
        font-weight: bold;
      }
      .creation {
        font-size: x-small
      }
    }
  }

  .text {
    white-space: preserve;
    font-size: small;
  }
}