.recording-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  .recording {
    width: 100%;
    border-radius: 5px;
    border: 1px solid black;
    list-style: none;
    padding: .5em;
    p {
      margin: 0;
    }
    &.expired {
      opacity: .5;
    }
  }

  hr {
    display: none;
    &:last-of-type {
      display: block;
    }
  }
}

