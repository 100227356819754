.apps {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  .app {
    * {
      color: #ccc;
    }
    text-decoration: none;
    background-color: #700000;
    border: 1px solid black;
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
    .icon {
      font-size: 5em;
    }

    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.5em;
    }

    p {
      margin: 0;
    }
  }
}