@import "@/main.scss";

.podcast {
  margin-top: 1em;
  display: flex;
  text-decoration: none;
  align-items: center;
  max-width: 100%;
  .artwork {
    width: 75px;
    flex-shrink: 0;
  }
  .info {
    overflow: hidden;
    
    p {
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .title {
      font-weight: bold;
    }
    .author {
      color: #666;
      font-size: small;
    }
  }
  gap: 1em;
}

.episode-title {
  font-size: 1em;
}

.actions {
  margin: 1em 0;
  display: flex;
  gap: 1em;
}

.episode {
  .pub-date {
    color: #888;
    font-size: smaller;
  }

  .description {
    white-space: preserve;
    line-height: 1.5em;
  }
}