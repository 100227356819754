.wrapper {
  position: fixed;
  bottom: 10px;
  width: 800px;
  max-width: 80%;
  left: 50%;
  transform: translate(-50%);

  .inner {
    width: 100%;
    margin: auto;
  }
}

.notification {
  position: relative;
  border: 1px solid black;
  border-color: transparent;
  box-shadow: 0px 0px 10px 3px #aaa;
  margin: 1em 0;
  border-radius: 5px;
  background-color: #530000;
  padding: 0em 1em;
  bottom: -100px;
  transition: bottom .3s ease;
  &.visible {
    bottom: 0;
  }

  * {
    color: white;
  }
}