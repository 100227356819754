@import '@/main.scss';

.feature-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: .25em;

  li.tag {
    &.global {
      background-color: lightgrey;
    }
    &.missing {
      background-color: lightgreen;
    }
    button {
      background-color: transparent;
      border: none;
    }
  }
}