@keyframes opacity {
  0% { opacity: 1; transform: scale(1); }
  60% { opacity: 1; transform: scale(1); }
  65% { opacity: .7; transform: scale(0.95); }
  70% { opacity: .9; transform: scale(1); }
  100% { opacity: 0; transform: scale(.8); }
}

@keyframes drawGrey {
  20% { stroke-dashoffset: 0; }
  100% { stroke-dashoffset: 0; }
}

@keyframes redOuter {
  20% { stroke-dashoffset: 120; }
  40% { stroke-dashoffset: 0; }
  100% { stroke-dashoffset: 0; }
}
@keyframes redShort {
  40% { stroke-dashoffset: 30; }
  50% { stroke-dashoffset: 0; }
  100% { stroke-dashoffset: 0; }
}
@keyframes redInner {
  45% { stroke-dashoffset: 100; }
  70% { stroke-dashoffset: 0; }
  100% { stroke-dashoffset: 0; }
}

.wrapper {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  svg {
    height: 100%;
    width: auto;
    $duration: 2s;
    animation: opacity $duration ease infinite;

    .path-grey {
      stroke-dashoffset: -130;
      stroke-dasharray: 130;
      animation: drawGrey $duration linear infinite;
    }

    .path-outer-red {
      stroke-dashoffset: 120;
      stroke-dasharray: 120;
      animation: redOuter $duration linear infinite;
    }
    .path-short-red {
      stroke-dasharray: 30;
      stroke-dashoffset: 30;
      animation: redShort $duration linear infinite;
    }
    .path-inner-red {
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
      animation: redInner $duration linear infinite;

    }


    path-xy {
      --line-length: 100;
      --delay: 0s;
      --animation-speed: .5s;
      --direction: normal;
        
      stroke-dasharray: var(--line-length);
      stroke-dashoffset: var(--line-length);
      animation: drawPath var(--animation-speed) linear forwards;
      animation-direction: var(--direction);
      animation-delay: var(--delay);

      &.path-red-3 {
        --delay: .5s;
        --line-length: 120;
      }
      &.path-red-1 {
        --delay: 1s;
      }
      &.path-red-2 {
        --delay: 1.1s;
      }
      &.path-grey {
      }
    }
  }
}