@import '@/main.scss';
$toggleWidth: 2px;
.footer {
  height: $footerHeight;
  width: 100%;
  .inner {
    height: $footerHeight;
  }
}

@media (min-width: 500.1px) {
  .footer {
    .inner {
      display: flex;
      align-items: center;

      .player-footer {
        width: 100%;
      }
    }
    
  }
}

@media (max-width: 500px) {
  .footer {
    width: 100vw;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    .inner {
      display: grid;
      grid-template-columns: calc(100vw - $toggleWidth - $footerHeight) calc(100vw - $toggleWidth);
      width: calc(200vw - $footerHeight - $toggleWidth);


      .context-footer {
        scroll-snap-align: start;
      }

      .player-footer {
        scroll-snap-align: start;
        border-left: 2px solid black;
      }
    }
    
  }
}