.input-renderer, .output-renderer, .rtc-renderer, .recording-renderer, .idb-file-renderer {
  border: 1px solid black;
  border-radius: 5px;
  background-color: var(--accent-background-color);
  width: 100%;

  &.attention {
    background-color: #ffacac;
  }

  &.rec {
    background-color: #02d314;
  }

  .timeout {
    font-size: 4em;
  }

  .icon {
    font-size: 2em;
    display: flex;
    align-items: center;
  }

  .content {
    overflow: hidden;
    padding-right: 3em;
    width: 100%;
    canvas {
      width: 100%;
      height: 3px;
    }
  }

  .mute {
    cursor: pointer;
    font-size: 1.2em;
    :global(.muted) * {
      color: red;
    }
  }

  position: relative;
  .connection-modal {
    position: absolute;
    top: 0;
    right: 0;
  }

  button.connection-modal {
    border: none;
    background-color: transparent;
    font-size: 1.25em;
    padding: 3px;
    cursor: pointer;
  }
}

.rtc-renderer {
  .visualizer {
    .in, .out {
      display: flex;
      align-items: center;
      span {
        width: 5em;
        font-size: 8px;
      }
      canvas {
        width: 100%;
        height: 3px;
      }
    }
  }
}

.recording-renderer {
  @media (min-width: 800px) {
    width: calc(50% - 0.5em)
  }
  ul {
    padding-left: 1em;
    margin: 0;
    li {
      list-style: none;
      font-size: 10px;
    }
  }
}

.idb-file-renderer {
  @media (min-width: 800px) {
    width: calc(50% - .5em);
  }

  .progress {
    width: 100%;
    input {
      width: 100%;
    }
  }

}

.in-out-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  gap: 1em;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.rec-row {
  flex-wrap: wrap;
}

.session-download-list {
  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    li {
      width: calc(50% - .5em);
      border: 1px solid black;
      padding: .5em;
      list-style: none;
      border: 1px solid black;
      border-radius: 5px;
      background-color: var(--accent-background-color);
      button {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: none;
        border: none;
        font-size: 2em;
      }
    }

  }
}

.action-bar {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1em;
  .ruler {
    border-left: 1px solid black
  }
  button {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
}

.idb-file-list {
  .buttons {
    display: flex;
    gap: .25em;
    button {
      border:none;
      background-color: transparent;
      font-size: 1.25em;
    }
  }
}