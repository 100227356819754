.list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  .entry {
    list-style: none;
    .first {
      display: flex;
      align-items: center;
      gap: 1em;
    }
  }
}