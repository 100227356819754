@import '@/main.scss';

.header {
  height: 100%;
  display: flex;
  align-items: center;
  padding: .5em 1em;
  box-sizing: border-box;
  gap: 1em;
  background-color: var(--background-color);
  box-shadow: 2px 2px 10px 2px var(--shadow-background-color);

  .menu-trigger {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .logo {
    height: 65%;
    display: flex;
    align-items: center;
    gap: .25em;
    text-decoration: none;
    svg {
      height: 100%;
      max-height: 30px;
      width: auto;
    }
    span {
      font-size: 1.4em;
      color: #530000;
      font-weight: 600;
    }
  }

  .search {
    width: 100%;
    height: 100%;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
  }
}