.artwork {
  border-radius: 5%;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.artwork-wrapper {
  aspect-ratio: 1 / 1;
  background: linear-gradient(-45deg, #53000038, #bbbbbb38, #53000038);
	background-size: 300% 300%;
	animation: gradient 3s linear infinite;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

  80% {
    background-position: 100% 50%;
  }

  100% {
		background-position: 0% 50%;
  }
}