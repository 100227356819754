:root {
  --text-color: #333;
  --background-color: #fafafa;
  --accent-background-color: #f1f1f1;
  --shadow-background-color: #aaa;

  @media (prefers-color-scheme: dark) {
    --text-color: #ccc;
    --background-color: #111;
    --accent-background-color: #444;
    --shadow-background-color: #666;
  }
}