$innerHeight: var(--modal-inner-height);

.outer {
  position: fixed;
  bottom: 0;
  left: 0; right: 0;
  height: 0;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  background-color: #000000;
  opacity: .5;
  display: none;
}

.inner-scrollable {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 800px;
  width: 100%;
  margin: auto;
  bottom: calc($innerHeight * -1);
  height: $innerHeight;
  transition: bottom .3s ease;
  overflow: auto;
  overscroll-behavior: none;
}



.inner-scrollcontainer {
  height: calc($innerHeight * 2);
  padding-top: $innerHeight;
  box-sizing: border-box;
}
.outer.notouch {
  .inner-scrollcontainer {
    padding-top: 0;
    height: $innerHeight;
  }
}

.header {
  text-align: center;
  background-color: #530000;
  color: #eee;
  font-weight: bold;
  padding: 1em;
  box-sizing: border-box;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  button {
    float: right;
    background-color: transparent;
    border: none;
    color: white;
  }
}

.inner {
  background-color: var(--background-color);
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  width: 100%;
  height: $innerHeight;
  display: flex;
  flex-direction: column;
  .content {
    height: 100%;
    overflow: auto;
    padding: 1em;
    box-sizing: border-box;
  }
}

.outer.visible {
  top: 0;
  height: auto;
  .background {
    display: block;
  }
  .inner-scrollable {
    bottom: 0;
  }
}

.outer.visible.pre-close {
  .inner-scrollable {
    bottom: calc($innerHeight * -1);
  }
}

