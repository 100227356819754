@import '@/main.scss';

.table {
  border: 1px solid $lightRed;
  border-collapse: collapse;
  width: 100%;
}

.th {
  text-align: left;
}

.th, .td {
  border: 1px solid $lightRed;
  padding: .25em;
}