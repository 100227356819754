@import "@/main.scss";

.header {
  display: flex;
  gap: 1em;
  padding-top: 1em;
  box-sizing: border-box;
  .artwork {
    width: 150px;
  }

  .info {
    width: 100%;
    .title {
      font-weight: bolder;
      font-size: 1.25em;
      margin: .25em 0;
    }
    .author {
      font-size: .75em;
      color: #aaa;
      margin: .25em 0;
    }
  }
}

.action-bar, .episode-action-bar {
  margin: 1em 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 1em;
}

.description {
  margin: 2em 0;
}

.list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  .list-entry {
    a {
      text-decoration: none;
    }
    list-style: none;
    
    .episode-pub-date {
      color: #888;
    }
    
    .episode-title {
      font-weight: bold;
      margin: 0;
      margin-bottom: .5em;
    }

    .episode-description {
      margin: 0;
    }
  }
}