@import "@/main.scss";

.episode-vertical {
  .info-row {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .pub-date {
    color: #888;
    margin: 0;
  }

  .title, .podcast-title {
    margin: 0;
  }

  .podcast-title {
    font-weight: bold;
  }

  .action-bar {
    display: flex;
    gap: 1em;
  }
}

.description {
  margin: 1em 0;
}

.podcast-horizontal {
  .title {
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    font-size: x-small;
    text-overflow: ellipsis;
    * {
      display: inline;
    }
    @media (min-width: 500px) {
      width: 200px;
      font-size: initial;
    }
  }
}