@import '@/main.scss';


.pod-table {
  width: 100%;
  border-collapse: collapse;
  td, th {
    border: 1px solid black;
    padding: .25em;

    &.url {
      word-break: break-all;
    }
  }

  th {
    text-align: left;
  }
}

.episodes {
  display: flex;
  flex-direction: column;
  gap: .5em;

  .episode {
    border: 1px solid black;
    padding: .5em;

    .description {
      font-size: xx-small;
    }
  }
}