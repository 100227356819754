.footer {
  display: flex;
  gap: 1em;
  align-items: center;
  gap: 1em;
  padding: 0 1em;
  box-sizing: border-box;
  height: 100%;
  select {
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 1em 0;
  }
}