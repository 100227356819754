@import '@/main.scss';

.modal-content {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .select-list {
    padding: 1em 0;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    .entry {
      list-style: none;
      width: 95%;
      margin: .5em auto;
      padding: .25em 1em;
      &.current {
        font-weight: bold;;
      }
    }
  }
  .add-entry {
    list-style: none;
    .add-row {
      display: flex;
    }
  }
}

.pause-after-entry {
  display: flex;
  align-items: center;
  .inner {
    width: 100%;
    background-color: var(--accent-background-color);
    border-radius: 5px;
    box-sizing: border-box;
    padding: .5em;
    cursor: pointer;
    p {
      margin: 0;
      &.title {
        font-weight: bold;
      }
      &.description {
        font-size: small;
      }
    }
  }
  
}

.episode-list {
  li {
    list-style: none;
  }
  padding: 0;
  max-width: 100%;
  .episode-list-entry {
    list-style: none;
    background-color: var(--background-color);
    a {
      text-decoration: none;
      display: grid;
      grid-template-columns: 70px calc(100% - 120px);
      gap: 1em;
      max-width: 100%;
      margin-bottom: .5em;
      .episode-info {
        .episode-pub-date {
          color: #888;
          margin: 0;
        }
        .podcast-title {
          margin: 0;
          font-weight: bold;
        }
        .episode-title {
          margin: 0;
        }
      }
    }
  }
}

.current-playlist {
  display: grid;
  grid-template-columns: calc(100% - 40px) 40px;
  align-items: center;
  .title {
    font-weight: bold;
  }
  .button {
    background-color: transparent;
    height: 40px;
    width: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    &:focus {
      outline: none;
    }
  }
}
.episode-action-bar {
  display: flex;
  align-items: center;
  gap: 1em;
  .remove-from-list-desktop {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .25em;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 1.25em;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
}
.drag-handle {
  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .75em;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 1.25em;
    cursor: pointer;
  }
}