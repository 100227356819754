@import '@/main.scss';

$progressHeight: 5px;

.square {
  width: $footerHeight;
  height: $footerHeight;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    width: 100%;
    height: 100%;
    button {
      background-color: transparent;
      border: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      cursor: pointer;
    }
  }
}

.slim {
  display: flex;
  flex-direction: column;
  height: $footerHeight;

  .main {
    display: flex;
    justify-content: space-between;
    height: calc(100% - $progressHeight);
    max-width: 100vw;
    .episode {
      margin: 0 .5em;
      display: flex;
      align-items: center;
      width: calc(100% - $footerHeight - $progressHeight);
      gap: .25em;

      p.title {
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .button {
      flex-shrink: 0;
      aspect-ratio: 1 / 1;
      height: 100%;

      button {
        background-color: transparent;
        border: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
        cursor: pointer;
      }
    }
  }

  .progress {
    height: $progressHeight;
    flex-shrink: 0;
    width: 100%;
  }
}

.player-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 2em;
  max-width: 100vw;
  overflow-x: hidden;
  a {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .artwork {}
  .episode-title {
    font-size: large;
    font-weight: normal;
    width: 100%;
    text-align: center;
    margin: 1em 0;
  }

  .podcast-title {
    font-size: small;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    margin: 1em 0;
  }
}

.progress {
  width: 100%;
  .bar {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    width: 100%;
  
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    border: none;
  
    --slider-height: 0.25em;
    --nimey-red: #b40000;
    --theme-text: lightgrey;
  
    &:focus {
      outline: none;
    }
  
    &::-webkit-slider-runnable-track {
      padding: 0;
      box-sizing: border-box;
      margin: 0;
      border: none;
      height: var(--slider-height);
      background: linear-gradient(to right, var(--nimey-red), var(--nimey-red) var(--playback-width), darkgrey var(--playback-width), darkgrey var(--buffered-width), var(--theme-text) var(--buffered-width), var(--theme-text) 100%);
    }
    &::-moz-range-track {
      padding: 0;
      box-sizing: border-box;
      margin: 0;
      border: none;
      height: var(--slider-height);  
      background: linear-gradient(to right, var(--nimey-red), var(--nimey-red) var(--playback-width), darkgrey var(--playback-width), darkgrey var(--buffered-width), var(--theme-text) var(--buffered-width), var(--theme-text) 100%);
    }
  
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--nimey-red);
      height: var(--slider-height);
      width: 2px;
    }
  
    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--nimey-red);
      height: var(--slider-height);
      width: 2px;
    }
  
    &:focus::-webkit-slider-thumb {   
      outline: none;
    }
  }
  .times {
    display: flex;
    justify-content: space-between;
    font-size: x-small;
  }
}
.progress.slim-progress {
  position: relative;
}
.progress.slim-progress .bar {
  --slider-height: 5px;
  height: $progressHeight;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 0;
  position: absolute;

  &::-webkit-slider-runnable-track {
    height: $progressHeight;
  }
  &::-moz-range-track {
    height: $progressHeight;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: $progressHeight;
    border: none;
  }

  &::-moz-range-thumb {
    height: $progressHeight;
    border: none;
  }
}

.controls {
  display: flex;
  gap: 1em;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;
  .play-pause {
    display: inline-block;
    font-size: 3em;
  }

  .seek {
    display: inline-block;
    font-size: 1.5em;
  }
}

.play-episode {
  --played-color: lightgrey;
  @media (prefers-color-scheme: dark) {
    --played-color: #555;
  }
  --not-played-color: transparent;
  button {
    appearance: none;
    -webkit-appearance: none;

    border: none;
    background-color: transparent;
    background: linear-gradient(to right, var(--played-color), var(--played-color) var(--played-percent), var(--not-played-color) var(--played-percent), var(--not-played-color) 100%);
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: .5em .75em;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: .5em;
    cursor: pointer;
    .icon {
      font-size: 1.5em;
    }
  }
}