@import '@/main.scss';

.icon {
  aspect-ratio: 1 / 1;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  box-sizing: border-box;
}