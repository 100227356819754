.description {
  box-shadow: 2px 2px 10px -3px var(--text-color);
  padding: 0.25em .5em;
  box-sizing: border-box;
}

.input-element {
  width: 100%;
  padding: .5em;
  border: none;
  border-bottom: 1px solid var(--text-color);
  &:focus {
    outline: none;
  }
}

.textarea-element {
  min-height: 15em;
}

.media {
  height: 6em;
  box-shadow: 2px 2px 10px -3px var(--text-color);
  padding: .5em;
  display: flex;
  align-items: center;

  .audio-file-button {
    .button {
      display: flex;
      font-size: 3em;
    }
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
      width: 0;
      height: 0;
    }
  }
  .mediaWrapper {
    display: flex;
    width: 100%;
    gap: 1em;
    align-items: center;
  }
  .player {
    display: flex;
    align-items: center;
    .play-button {
      font-size: 3em;
      display: flex;
    }
  }
}

.actions {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 1em;
  .desc {
    p {
      margin: 0;
    }
  }
  .action {
    button {
      width: 100%;
    }
  }
}

.dateInput {
  border: none;
  background-color: transparent;
}