@import '@/main.scss';

.background {
  position: absolute;
  top: 0; bottom: 0;
  left: 0;
  background-color: #530000;
  opacity: 0;
  transition: opacity .5s ease;
  &.visible {
    right: 0;
    opacity: .2;
  }
}

.menu {
  position: fixed;
  left: 0px;
  top: 0; bottom: 0;
  background-color: var(--background-color);
  margin-left: -100%;
  transition: margin .3s ease;
  box-sizing: border-box;

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
    }
  }

  &.visible {
    margin-left: 0;
  }
}

.menu-item {
  color: var(--text-color);
  padding: .5em 15px;
  margin-bottom: 1em;
  margin-top: 1em;
  margin-right: 2em;
  padding-right: 2em;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  box-sizing: border-box;

  &.light {
    padding: 0em 15px;
    font-size: small
  }
  a {
    text-decoration: none;
    font-weight: bold;

  }

  &:hover {
    color: #530000;
    a {
      color: #530000;
    }
  }
}

.divider {
  height: 0;
  width: 100%;
  border-bottom: 1px solid $accentRed;
}