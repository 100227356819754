.search {
  background-color: var(--accent-background-color);
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  border: none;
  padding: 1em;
  display: flex;
  gap: 1em;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  transition: width .3s ease, margin .3s ease;
  width: 100%;
  &:focus-within {
    background-color: var(--accent-background-color);
    box-shadow: 0px 0px 5px 5px var(--accent-background-color);
  }
  .icon {
    font-size: 2em;
    margin-top: 5px;
    color: var(--text-color);
  }
  input {
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  @media (max-width: 500px) {
    background-color: transparent;
    padding-left: 0;
    overflow: hidden;
    justify-content: flex-end;
    gap: 0;
    padding-right: 0;
    input {
      width: 0;
      overflow: hidden;
      justify-content: flex-end;
    }
    &:focus-within {
      padding-left: 1em;
      width: calc(100% + 200px);
      margin-left: -200px;
      gap: 1em;
      padding-right: 1em;
      input {
        width: 100%;
      }
    }
  }
}
