@import '@/main.scss';

.page {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden;
  background-color: var(--background-color);
}

.content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.header {
  height: $headerHeight;
}

.footer {
  box-shadow: -2px -2px 10px 0px var(--shadow-background-color);
  height: $footerHeight;
}