@import '@/main.scss';

.user-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: $mobileBreakpoint) {
    grid-template-columns: 1fr;
  }
  padding: 0;
  box-sizing: border-box;
  gap: 1em;
  li {
    list-style-type: none;
  }
}
.card {
  a {
    border: none;
    color: $textColor;
    text-decoration: none;
    padding: .75em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 1em;
  }
  p {
    margin: 0;
  }
}

.icon {
  aspect-ratio: 1 / 1;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  box-sizing: border-box;
}