.list-item {
  padding: 0;
  list-style: none;
  margin: 1em 0;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  button {
    width: 150px;
  }
}