@import '@/main.scss';

.feature-list {
  padding: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 1em;

  .feature-list-entry {
    list-style-type: none;
    padding: 1em;
    .headline {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.add-user-card, .remove-user-card {
  padding: .25em;
}

.user-list {
  padding: 0;
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
  li {
    list-style: none;
  }
}