.outer {
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .background {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background-color: var(--text-color);
    opacity: .6;
  }

  .notification {
    position: relative;
    width: 800px;
    max-width: 80%;
    background-color: var(--background-color);
    border-radius: 5px;
    padding: .25em 1em;
    padding-bottom: 1em;
    box-sizing: border-box;

    .close {
      float: right;
      padding-top: .5em;
      font-size: 1.2em;
    }
  }

  

}