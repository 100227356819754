@import '@/main.scss';

.ticket-list {
  padding: 0;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1em;
  li.ticket-list-entry {
    list-style: none;
    .card {
      padding: .5em;
      display: flex;
      justify-content: space-between;

      &.closed {
        opacity: .7;
        text-decoration: line-through;
      }
    }
  }
}