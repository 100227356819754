$headerHeight: 65px;
$footerHeight: 65px;

$mobileBreakpoint: 500px;

$accentRed: #b40000;
$lightRed: #b4000034;

$textColor: var(--text-color);

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.25em;
}

.card {
  background-color: $lightRed;
  border-radius: 5px;
  box-shadow: 2px 2px 10px -5px;
}

.tag {
  background-color: $lightRed;
  display: inline-block;
  padding: 0 1em;
  border-radius: 1em;
}

.artwork {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  max-width: 100%;
  aspect-ratio: 1/1;
}

.footer-icon-bar {
  display: flex;
  align-items: center;
  height: 100%;
  .icon {
    padding: 0;
    font-size: 1.5em;
    height: 100%;
    width: $footerHeight;
    height: $footerHeight;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    transition: box-shadow .2s ease;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:active, &.active {
      box-shadow: 0px 0px 8px 0px rgba(#530000, 0.2);
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.button {
  font-size: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.markdown {
  p {
    margin: 0;
  }
  a {
    color: #530000;
  }
}